<template>
  <div class="trial-use">
    <div>
      <div class="left">
        <p class="p1">1000+企业优佳选择</p>
        <p class="p2">用技术帮助您数字化转型</p>
        <div>
          <div class="div3">
            <p>合作伙伴</p>
            <div class="logo-box">
              <div v-for="(item, index) in logoList" :key="index">
                <img @click="openWindow" :src="item" />
              </div>
            </div>
          </div>
          <div class="div4">
            <div>
              <p>
                欢迎致电
                <span style="font-size: 17px; color: #3f6dc4"
                  >020-39984322</span
                >
                咨询
              </p>
              <div class="phone">
                <span>专业一对一服务，24小时在线</span>
                <img src="../../assets/images/onepage22.png" alt="" />
              </div>
            </div>
            <img
              @click="openWindow"
              src="../../assets/images/ContactInfo1.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="right">
        <p class="p1">云体系商业版免费试用</p>
        <p class="p2">专业一对一服务，提供最合适您的产品</p>
        <el-form :model="from">
          <el-form-item label="姓名" class="is-required" prop="userName">
            <el-input
              v-model="from.userName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机" class="is-required" prop="userPhone">
            <div class="inp-phone">
              <country-code-selector
                :countryCode.sync="value"
              ></country-code-selector>
              <el-input
                placeholder="请输入手机号"
                type="Number"
                v-model="from.userPhone"
                @blur="validatePhoneNumber"
                :class="{ phoneErrorClass: !phoneError }"
              ></el-input></div></el-form-item
          ><el-form-item label="企业名称" class="is-required">
            <el-input
              v-model="from.userCompany"
              placeholder="请输入企业名称"
            ></el-input> </el-form-item
          ><el-form-item label="职位" class="is-required">
            <el-input
              v-model="from.userPost"
              placeholder="请输入职位"
            ></el-input> </el-form-item
          ><el-form-item label="邮箱" class="is-required">
            <el-input
              @blur="validateEmail"
              v-model="from.userEmail"
              placeholder="请输入邮箱"
              :class="{ emailErrorClass: !emailError }"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button @click="submit()"> 免费申请</el-button>
        <p class="p3">
          请您务必保进以上信息的准确性，我们会第一时间和您取得系~
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import countryCodeSelector from "vue-country-diacode-selector";
import { post } from "../../api/http";

export default {
  components: {
    countryCodeSelector,
  },
  data() {
    return {
      value: 86,
      logoList: [
        require("../../assets/images/logo/logo1.png"),
        require("../../assets/images/logo/logo2.png"),
        require("../../assets/images/logo/logo3.png"),
        require("../../assets/images/logo/logo4.png"),
        require("../../assets/images/logo/logo5.png"),
        require("../../assets/images/logo/logo6.png"),
        require("../../assets/images/logo/logo7.png"),
        require("../../assets/images/logo/logo8.png"),
        require("../../assets/images/logo/logo9.png"),
        require("../../assets/images/logo/logo10.png"),
        require("../../assets/images/logo/logo11.png"),
        require("../../assets/images/logo/logo12.png"),
        require("../../assets/images/logo/logo13.png"),
        require("../../assets/images/logo/logo14.png"),
        require("../../assets/images/logo/logo15.png"),
        require("../../assets/images/logo/logo16.png"),
      ],
      from: {
        id: 0,
        userName: "",
        userPhone: "",
        userCompany: "",
        userPost: "",
        userEmail: "",
        saveTime: new Date().toISOString(),
        statues: 0,
        disPoseUserNo: "",
        disPoseTime: new Date().toISOString(),
      },
      phoneError: true,
      emailError: true,
    };
  },
  mounted() {},
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
    validatePhoneNumber() {
      const regex = /^1[3-9]\d{9}$/; // 中国大陆手机号正则表达式
      this.phoneError = regex.test(this.from.userPhone);
      if (!regex.test(this.from.userPhone)) {
        this.$message({
          type: "warning",
          message: "手机号无效",
        });
      }
    },
    validateEmail() {
      const regex =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; // 中国大陆手机号正则表达式
      this.emailError = regex.test(this.from.userEmail);
      if (!regex.test(this.from.userEmail)) {
        this.$message({
          type: "warning",
          message: "邮箱格式错误",
        });
      }
    },
    showMessage(mes) {
      this.$message({
        type: "warning",
        message: "请输入" + mes,
      });
    },
    submit() {
      const regex =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!this.from.userName) {
        this.showMessage("姓名");
      } else if (!this.from.userPhone) {
        this.showMessage("电话");
      } else if (!/^1[3-9]\d{9}$/.test(this.from.userPhone)) {
        this.showMessage("正确手机号");
      } else if (!this.from.userCompany) {
        this.showMessage("企业名称");
      } else if (!this.from.userPost) {
        this.showMessage("职位");
      } else if (!regex.test(this.from.userEmail)) {
        this.showMessage("正确格式邮箱");
      } else {
        post("/api/TryOutUserList", this.from)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "提交成功",
              });
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("提交失败");
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.trial-use {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets//images/onepage5.png");
  background-size: 100%;
  padding-top: 4%;
  > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 52%;
    margin-left: 24%;
    .left {
      .p1 {
        background: #e16565;
        color: white;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 7px;
      }
      .p2 {
        font-size: 0.4rem;
        margin-top: 15px;
        margin-bottom: 20px;
      }
      .div3 {
        background: white;
        border-radius: 10px 10px 0 0;
        text-align: center;
        padding: 20px 15px;
        > p {
          margin-bottom: 15px;
          font-size: 0.2rem;
        }
        .logo-box {
          width: 4.8rem; //(80*40)宽+(2+2*4)边距+(1+1*4)边框
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;

          > div {
            width: 1.1rem;
            height: 50px;
            background: white;
            margin: 0.03rem;
            border: 1px solid #c0ccf1;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .div4 {
        background-color: #f5f6fb;
        display: flex;
        padding: 0.25rem 0.2rem;
        border-radius: 0 0 10px 10px;
        img {
          width: 1rem;
          height: 1rem;
          margin-left: 15%;
        }
        span {
          cursor: default;
        }
        > div {
          margin-top: 3.3%;
          img {
            width: 0.32rem;
            height: 0.32rem;
            margin-left: 5px;
          }
        }
        .phone {
          display: flex;
          align-items: center;
          margin-top: 5%;
          font-size: 0.16rem;
          color: #858899;
        }
      }
    }
    .right {
      background-color: white;
      margin-left: 20px;
      margin-top: 80px;
      padding: 25px 40px;
      border-radius: 10px;
      .p1 {
        font-size: 0.22rem;
      }
      .p2,
      .p3 {
        margin-top: 3%;
        font-size: 0.15rem;
        color: #858899;
      }
      .el-form {
        margin-top: 6%;
        ::v-deep .el-form-item__label {
          line-height: 10px !important;
          font-size: 13px !important;
          float: none;
        }
        ::v-deep .el-form-item {
          margin-bottom: 5px !important;
        }
      }
      .inp-phone {
        display: flex;
        align-items: center;
        /deep/.el-input__inner {
          padding-left: 90px !important;
        }
      }
      .el-button {
        width: 100%;
        background-color: #3f6dc4 !important;
        color: white;
        margin-top: 8%;
      }
    }
  }
}
/deep/ .flag {
  display: none !important;
}
/deep/ .area-codeNum {
  padding: 0 !important;
  margin-right: 8px;
}
/deep/ .intl-tel-input-container {
  display: flex;
  justify-content: center;
  position: absolute !important;
  padding: 0 !important;
  top: 7px;
  height: 29px !important;
  z-index: 9999;
  border-right: 1px solid silver;
  width: 75px;
}
/deep/ .area-codeNum {
  font-size: 0.16rem !important;
}
/deep/ .flag-list-box {
  z-index: 9999 !important;
  background-color: whitesmoke;
  > .search-input {
    border-color: rgb(195, 195, 195);
  }
}
.phoneErrorClass:after {
  position: absolute;
  content: "手机号无效";
  width: 100%;
  color: red;
  display: block;
  top: 26px;
  text-align: end;
  font-size: 10px;
}
.emailErrorClass:after {
  position: absolute;
  content: "邮箱格式错误";
  width: 100%;
  color: red;
  display: block;
  top: 26px;
  text-align: end;
  font-size: 10px;
}
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>